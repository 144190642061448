import "./../../css/about-us.css"

const AboutUs = () => {
    return (
        <main className="about">
            <h4>О Компании</h4>

            <div className="about__top">
                <div className="about-top__left">
                    <h5>Кто мы</h5>
                    <span>Наша команда объединила в себе не просто рабочих, а профессионалов этой ниши, к каждому заказу наши бригады подходят индивидуально и добиваются безупречных результатов!Наша команда объединила в себе не просто рабочих, а профессионалов этой ниши, к каждому заказу наши бригады подходят индивидуально и добиваются безупречных результатов!Наша команда объединила в себе не просто рабочих, а профессионалов этой ниши, к каждому заказу наши бригады подходят индивидуально и добиваются безупречных результатов!</span>
                </div>

                <div className="about-top__right"></div>
            </div>

            <div className="about__bottom">
                <div className="about-bottom__left"></div>

                <div className="about-bottom__right">
                    <h5>Кто мы</h5>
                    <span>• Клиентоориентированность - стремление к полному удовлетворению потребностей клиентов и ориентация на конечный результат. <br/><br/>

• Персонал - сильная команда специалистов, обладающих уникальными компетенциями и стремящихся к непрерывному развитию.<br/><br/>

• Доверие - максимальная открытость
результатов деятельности для клиентов и сотрудников, ориентация на долгосрочное сотрудничество.<br/><br/>

• Развитие - стремление к росту масштабов деятельности за счет создания новых конкурентных преимуществ, использования современных строительных и управленческих технологий.</span>
                </div>
            </div>
        </main>
    );
}

export default AboutUs;
