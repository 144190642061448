import React, { useState } from 'react';
import "./../../css/header.css";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header>
            <div className="logo">Logo</div>

            <nav className={`header__nav ${menuOpen ? 'open' : ''}`}>
                <a href="#" className="header-nav__link">Главная</a>
                <a href="#" className="header-nav__link">Главная</a>
                <a href="#" className="header-nav__link">Главная</a>
                <a href="#" className="header-nav__link">Главная</a>
                <a href="#" className="header-nav__link">Главная</a>
            </nav>

            <a href="tel:+79999999999" className="header__tel">+7 (999) 999-99-99</a>

            <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </header>
    );
}

export default Header;
