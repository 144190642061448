import './App.css';
import Header from "./components/header/header";
import Main from "./components/blocks/block-main";
import Footer from "./components/footer/footer";
import AboutUs from "./components/blocks/about-us";
import Reviews from "./components/blocks/reviews";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import News from "./components/blocks/news";
import Map from "./components/blocks/map";
import Projects from "./components/blocks/projects";
import Crovel from "./components/blocks/crovel";
import Form from "./components/blocks/form";
import Process from "./components/blocks/process";
import { useState } from 'react';
import Modal from "./components/blocks/modal";


const cardsData = [
    {
        title: "Кровельные работы 1",
        description: "Описание первого типа кровельных .",
        buttonText: "Подробнее",
        image: "https://example.com/image1.jpg"
    },
    {
        title: "Кровельные работы 2",
        description: "Описание второго типа кровельных работ.",
        buttonText: "Подробнее",
        image: "https://example.com/image2.jpg"
    },
    {
        title: "Кровельные работы 3",
        description: "Описание третьего типа кровельных работ.",
        buttonText: "Подробнее",
        image: "https://example.com/image3.jpg"
    },
    // Добавьте больше карточек по мере необходимости
];


const App = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

  return (
    <div className="App">
      <Header/>
      <Main  openModal={openModal}/>
        {isModalOpen && <Modal closeModal={closeModal} />}
        <Crovel cards={cardsData}/>
        <AboutUs/>
        <Projects/>
        <Process/>
        <Reviews/>
        <News/>
        <Form/>
        <Map/>
      <Footer/>
    </div>
  );
}

export default App;
