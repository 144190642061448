import React from "react";
import Slider from "react-slick";
import "./../../css/reviews.css";

const testimonials = [
    {
        id: 1,
        name: "Иван Иванов",
        date: "19 авг 2022",
        rating: 4,
        text: "Спасибо за проделанную работу, результат оправдал ожидания.",
        imageUrl: "path_to_image_1", // Заменить на фактический путь к изображению
    },
    {
        id: 2,
        name: "Иван Иванов",
        date: "19 авг 2022",
        rating: 5,
        text: "Спасибо за проделанную работу, результат оправдал ожидания.",
        imageUrl: "path_to_image_2", // Заменить на фактический путь к изображению
    },
    {
        id: 3,
        name: "Иван Иванов",
        date: "19 авг 2022",
        rating: 5,
        text: "Спасибо за проделанную работу, результат оправдал ожидания.",
        imageUrl: "path_to_image_3", // Заменить на фактический путь к изображению
    },
    {
        id: 4,
        name: "Иван Иванов",
        date: "20 авг 2022",
        rating: 5,
        text: "Отличная работа, всем доволен.",
        imageUrl: "path_to_image_4", // Заменить на фактический путь к изображению
    },
];

const TestimonialsSlider = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <div className="testimonials-slider-section">
            <h2>Почему нас выбирают?</h2>
            <Slider {...settings}>
                {testimonials.map((testimonial) => (
                    <div key={testimonial.id} className="testimonial-card">
                        <div className="reviews">
                            <img
                                src={testimonial.imageUrl}
                                alt={testimonial.name}
                                className="testimonial-avatar"
                            />

                            <div className="name-date">
                                <h3>{testimonial.name}</h3>
                                <p>{testimonial.date}</p>
                            </div>


                            <div className="testimonial-rating">
                                {Array(testimonial.rating)
                                    .fill()
                                    .map((_, i) => (
                                        <span key={i} className="star">⭐</span>
                                    ))}
                            </div>
                        </div>

                        <div className="testimonial-info">

                            <p className="testimonial-text">{testimonial.text}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};


const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "-30px" }}
            onClick={onClick}
        />
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "-30px" }}
            onClick={onClick}
        />
    );
};

export default TestimonialsSlider;