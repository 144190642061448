import React from 'react';
import "../../css/projects.css"

const Projects = () => {

    return (
        <main className="projects">
            <div className="projects__header">
                <h2>Наши проекты</h2>

                <h2>
                    ↻
                </h2>
            </div>

            <div className="projects__grid">
                <div className="projects__top">
                    <div className="project">
                        <div className="project__header">
                            <h5>Name</h5>
                            <h6>Name</h6>
                        </div>

                        <a href="" className="project__btn">Подробнее</a>
                    </div>

                    <div className="project">
                        <div className="project__header">
                            <h5>Name</h5>
                            <h6>Name</h6>
                        </div>

                        <a href="" className="project__btn">Подробнее</a>
                    </div>

                    <div className="project">
                        <div className="project__header">
                            <h5>Name</h5>
                            <h6>Name</h6>
                        </div>

                        <a href="" className="project__btn">Подробнее</a>
                    </div>
                </div>

                <div className="project" style={{flex: "inherit"}}>
                    <div className="project__header">
                        <h5>Name</h5>
                        <h6>Name</h6>
                    </div>

                    <div className="project__bottom">
                        <h4>asedsadasd</h4>

                        <a href="" className="project__btn">Подробнее</a>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Projects;
