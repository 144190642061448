import React from 'react';
import Slider from 'react-slick';
import './../../css/crovel.css'; // Ваши стили

const SliderComponent = ({ cards }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="slider-container">
            <h2>ВИДЫ КРОВЕЛЬНЫХ РАБОТ</h2>
            <Slider {...settings}>
                {cards.map((card, index) => (
                    <div key={index} className="card">
                        <div className="card-image" style={{ backgroundImage: `url(${card.image})` }}>
                            {/* Вы можете использовать изображение как background-image */}
                        </div>
                        <div className="card-content">
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                            <button className="card-button">{card.buttonText}</button>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', color: 'orange' }}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', color: 'orange' }}
            onClick={onClick}
        />
    );
}

export default SliderComponent;
