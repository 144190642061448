import React from 'react';
import './../../css/process.css';

const steps = [
    { id: 1, title: "Заявка", description: "Вы оставляете заявку" },
    { id: 2, title: "Связь", description: "Наши менеджеры связываются с вами для бесплатной консультации" },
    { id: 3, title: "Детали", description: "Обсуждение деталей, составление Технического задания" },
    { id: 4, title: "Предложение", description: "Мы составляем коммерческое предложение с лучшей ценой" },
    { id: 5, title: "Договор", description: "Подписываем Договор и ТЗ отправляется команде профессионалов" },
    { id: 6, title: "Готовый продукт", description: "В установленные сроки Вы получаете готовый продукт. Работаем быстро и на результат" },
];

const ProcessSteps = () => {
    return (
        <div className="process-container">
            <h2>Структура работы</h2>
            <h1>Построение процесса</h1>

            <div className="steps-container">
                <div className="step-box step-1">
                    <div className="step-number">1</div>
                    <div className="step-content">
                        <h3>Заявка</h3>
                        <p>Вы оставляете заявку</p>
                    </div>
                </div>
                <div className="step-box step-2">
                    <div className="step-number">2</div>
                    <div className="step-content">
                        <h3>Связь</h3>
                        <p>Наши менеджеры связываются с вами для бесплатной консультации</p>
                    </div>
                </div>
                <div className="step-box step-3">
                    <div className="step-number">3</div>
                    <div className="step-content">
                        <h3>Детали</h3>
                        <p>Обсуждение деталей, составление Технического задания</p>
                    </div>
                </div>
                <div className="step-box step-4">
                    <div className="step-number">4</div>
                    <div className="step-content">
                        <h3>Предложение</h3>
                        <p>Мы составляем коммерческое предложение с лучшей ценой</p>
                    </div>
                </div>
                <div className="step-box step-5">
                    <div className="step-number">5</div>
                    <div className="step-content">
                        <h3>Договор</h3>
                        <p>Подписываем Договор и ТЗ отправляется команде профессионалов</p>
                    </div>
                </div>
                <div className="step-box step-6">
                    <div className="step-number">6</div>
                    <div className="step-content">
                        <h3>Готовый продукт</h3>
                        <p>В установленные сроки вы получаете готовый продукт</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessSteps;