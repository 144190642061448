import "./../../css/main.css"

const Main = ({ openModal }) => {
    return (
        <main className="block1">
            <h1>кровельные работы</h1>
            <h2>в москве и московской области</h2>
            <h3>в москве и московской области</h3>

            <a onClick={openModal} className="block1__btn">оставить заявку</a>
        </main>
    );
}

export default Main;
