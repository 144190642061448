import "./../../css/footer.css"

const Footer = () => {
    return (
        <footer>
            <div className="footer__first">
                <div className="logo">Logo</div>

                <div>
                    <a href="#" className="footer__privacy">Политика конфиденциальности</a>
                    <a href="#" className="footer__privacy">Пользовательское соглашение</a>
                </div>
            </div>

            <div className="footer__second">
                <h5 className="footer-link__title">Навигация</h5>
                <div>
                    <a href=""><h6 className="footer__link">Главная</h6></a>
                    <a href=""><h6 className="footer__link">О нас</h6></a>
                    <a href=""><h6 className="footer__link">Услуги</h6></a>
                    <a href=""><h6 className="footer__link">Наши проекты</h6></a>
                    <a href=""><h6 className="footer__link">Контакты</h6></a>
                    <a href=""><h6 className="footer__link">Форма</h6></a>
                </div>
            </div>

            <div className="footer__thride">
                <h5 className="footer-link__title">Контакты</h5>
                <a href=""><h6 className="footer__link">г. СПб</h6></a>
                <a href=""><h6 className="footer__link">+8 999 999 99 99</h6></a>
                <a href=""><h6 className="footer__link">example@example.com</h6></a>
                
                <div className="footer-thride__socialpack">
                    <a href=""></a>
                    <a href=""></a>
                    <a href=""></a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
