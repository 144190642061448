import React from "react";
import "../../css/news.css"

const News = () => {
    return (
        <main className="news__main">
            <h2>Новости</h2>

            <div className="news">
                <div className="news__item">
                    <div className="news-item__left">
                        <h3>DSAD</h3>
                        <h6>12.08.2005</h6>

                        <a href="" className="news-item__btn">Смотреть</a>
                    </div>

                    <div className="news-item__right"></div>
                </div>

                <div className="news__item reverse">
                    <div className="news-item__left">
                        <h3>DSAD</h3>
                        <h6>12.08.2005</h6>

                        <a href="" className="news-item__btn">Смотреть</a>
                    </div>

                    <div className="news-item__right"></div>
                </div>

                <div className="news__item">
                    <div className="news-item__left">
                        <h3>DSAD</h3>
                        <h6>12.08.2005</h6>

                        <a href="" className="news-item__btn">Смотреть</a>
                    </div>

                    <div className="news-item__right"></div>
                </div>
            </div>
        </main>
    );
};

export default News;