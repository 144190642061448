import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './../../css/form.css'; // Ваши стили

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'YOUR_SERVICE_ID',  // Замените на ваш service ID
            'YOUR_TEMPLATE_ID',  // Замените на ваш template ID
            form.current,
            'YOUR_USER_ID'       // Замените на ваш public ключ
        )
            .then((result) => {
                console.log(result.text);
                alert('Сообщение успешно отправлено!');
            }, (error) => {
                console.log(error.text);
                alert('Произошла ошибка при отправке сообщения.');
            });

        e.target.reset();
    };

    return (
        <div className="contact-container">
            <div className="contact-info">
                <h3>Контакты</h3>
                <h2>Остались вопросы?</h2>
                <p><i className="fas fa-phone"></i> +7 (999) 000-00-00</p>
                <p><i className="fas fa-envelope"></i> info@info.ru</p>
            </div>
            <div className="contact-form">
                <h3>Заполните форму</h3>
                <p>И мы свяжемся с вами</p>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" placeholder="Имя" required />
                    <input type="email" name="user_email" placeholder="Email" required />
                    <textarea name="message" placeholder="Сообщение" required />
                    <div className="checkbox-container">
                        <input type="checkbox" name="consent" required />
                        <label>
                            Согласен с обработкой моих персональных данных в соответствии с
                            <a href="/privacy-policy"> политикой конфиденциальности.</a>
                        </label>
                    </div>
                    <button type="submit">Отправить</button>
                </form>
            </div>

            <div className="contact-info mobile">
                <h3>Контакты</h3>
                <h2>Остались вопросы?</h2>
            </div>
        </div>
    );
}

export default ContactForm;
