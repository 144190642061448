import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import "../../css/map.css"

const OfficeMap = () => {
    // Массив с координатами и информацией для каждой метки
    const places = [
        {
            coordinates: [55.751574, 37.573856],
            header: 'Офис в Москве',
            body: 'Адрес: Москва, Красная площадь',
        },
        {
            coordinates: [59.9342802, 30.3350986],
            header: 'Офис в Санкт-Петербурге',
            body: 'Адрес: Санкт-Петербург, Невский проспект',
        },
        {
            coordinates: [55.831903, 37.411961],
            header: 'Офис в Химках',
            body: 'Адрес: Химки, Ленинградское шоссе',
        },
    ];

    return (
        <main className="map__block">
            <h2>Где мы работаем?</h2>

            <div className="map">
                <YMaps>
                    <Map
                        defaultState={{
                            center: [55.751574, 37.573856],
                            zoom: 5,
                        }}
                        width="100%"
                        height="500px"

                    >
                        {places.map((place, index) => (
                            <Placemark
                                key={index}
                                geometry={place.coordinates}
                                properties={{
                                    balloonContentHeader: place.header,
                                    balloonContentBody: place.body,
                                }}
                                options={{
                                    preset: 'islands#icon',
                                    iconColor: '#0095b6',
                                }}
                                modules={['geoObject.addon.balloon']}
                            />
                        ))}
                    </Map>
                </YMaps>
            </div>
        </main>
    );
};

export default OfficeMap;
