import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './../../css/form.css'; // Ваши стили

const ContactForm = ({ closeModal }) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'YOUR_SERVICE_ID',  // Замените на ваш service ID
            'YOUR_TEMPLATE_ID',  // Замените на ваш template ID
            form.current,
            'YOUR_USER_ID'       // Замените на ваш public ключ
        )
            .then((result) => {
                console.log(result.text);
                alert('Сообщение успешно отправлено!');
            }, (error) => {
                console.log(error.text);
                alert('Произошла ошибка при отправке сообщения.');
            });

        e.target.reset();
        closeModal();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={closeModal}>X</button>
                <h3>Заполните форму</h3>
                <p>И мы свяжемся с вами</p>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" placeholder="Имя" required />
                    <input type="tel" name="user_tel" placeholder="+7" required />
                    <input type="email" name="user_email" placeholder="Email" required />
                    <textarea name="message" placeholder="Сообщение" required />
                    <div className="checkbox-container">
                        <input type="checkbox" name="consent" required />
                        <label>
                            Согласен с обработкой моих персональных данных в соответствии с
                            <a href="/privacy-policy"> политикой конфиденциальности.</a>
                        </label>
                    </div>
                    <button type="submit">Отправить</button>
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
